import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { InformationFlow } from '@ifeng-fe/three_terminal/es/mobile/index';
import { getColumnInfo } from '../../../services/api';

class NewsList extends PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    constructor(...argu) {
        super(...argu);

        const { newsstream, preloadNewsstream, commonIdArray } = this.props.content;

        this.lastItem = preloadNewsstream ? preloadNewsstream[preloadNewsstream.length - 1] : null;
        this.lastItem = this.lastItem === null && newsstream ? newsstream[newsstream.length - 1] : this.lastItem;

        this.repeatedID = [...commonIdArray];
    }
    // 加载更多
    handleMoreData = async () => {
        // 结束
        if (!this.lastItem) {
            return { content: [], isEnd: true };
        }

        const { newsTime, id } = this.lastItem;
        const time = new Date(newsTime).getTime();
        const { columnId, newsStream } = this.props.content;

        const argu = [];

        if (newsStream) argu.push(newsStream.key, newsStream.type);
        else argu.push(columnId);

        const { data } = await getColumnInfo(id, time, 20, ...argu);

        // 去重
        for (let i = data.newsstream.length - 1; i >= 0; i--) {
            if (!data.newsstream[i].id) continue;

            if (this.repeatedID.includes(data.newsstream[i].id)) {
                data.newsstream.splice(i, 1);
            } else {
                this.repeatedID.push(data.newsstream[i].id);
            }
        }

        this.lastItem = data.newsstream[data.newsstream.length - 1];

        return {
            content: data.newsstream,
            isEnd: data.isEnd,
        };
    };

    render() {
        const { newsstream, isEnd, preloadNewsstream, ad, isPic } = this.props.content;
        const newsStreamProps = {
            content: newsstream,
            isEnd,
            preloadContent: preloadNewsstream,
        };

        return newsstream ? (
            <InformationFlow
                isDisplaySource={true}
                isLazyImage={true}
                isScrollLoadingData={true}
                isPic={isPic}
                preload={true}
                isImageAnimate={true}
                isDisplaySlideTujiText={true}
                handleLoadingDataFunc={this.handleMoreData}
                ad={ad ? [ad] : undefined}
                {...newsStreamProps}
            />
        ) : null;
    }
}

export default NewsList;
