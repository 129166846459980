import SliderComponent from './SliderComponent';
import NewsStreamComponent from './NewsStreamComponent';
import NewsInTopComponent from './NewsInTopComponent';
import StockDataComponent from './StockDataComponent/load';
import AccountOpeningComponent from './AccountOpeningComponent/load';

export default {
    SliderComponent,
    NewsStreamComponent,
    NewsInTopComponent,
    StockDataComponent, // 股票走势
    AccountOpeningComponent, // 开户交易
};
