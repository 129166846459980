import { jsonp } from '@ifeng-fe/ui_base';
// import { cookie } from '@ifeng-fe/three_terminal/es/base/base';

/* 点击更多，获取信息流 */
/* eslint-disable */
const apiBaseUrl = typeof apiUrl !== 'undefined' ? apiUrl : '/api'; // apiUrl为webpack注入的全局变量
/* eslint-enable */

export const getColumnInfo = async function getColumnInfo(
    id,
    time,
    size,
    columnId,
    dataTypeName = 'default',
    urlId = '_',
) {
    return await jsonp(
        `${apiBaseUrl}/_/getColumnInfo_seo/${urlId}/${dataTypeName}/${id}/${time}/${size}/${columnId}/getColumnInfoCallback`,
        {
            jsonpCallback: 'getColumnInfoCallback',
        },
    );
};
// 获取视频播放数
export const getVideoComment = async vid => {
    // const { schemaCheck } = await import('./validate');

    let apiUrl = '//survey.news.ifeng.com/api/getaccumulatorweight?';

    // 接口拼接
    if (vid.length > 0) {
        vid.forEach(item => {
            apiUrl += `key[]=${item}&`;
        });
    }

    let data = await jsonp(apiUrl, {
        data: {
            format: 'js',
            serviceid: 1,
            callback: 'getVideoComment',
        },
        jsonCallback: 'getVideoComment',
        cache: false,
    });
    // const requestUrl = `//survey.news.ifeng.com/api/getaccumulatorweight?format=js&key=${vid}&callback=getaccumulator`;

    // data = schemaCheck(data.data, 'voteNumSchema', requestUrl);
    data = data.data;

    return data ? data : null;
};

// 视频信息流
// export const getVideoStream = async (page, pageSize, columnId, source) => {
//     return await jsonp(`${apiBaseUrl}/getVideoStream/${page}/${pageSize}/${columnId}/${source}/getVideoStream`, {
//         jsonpCallback: 'getVideoStream',
//         cache: false,
//     });
// };

// 获取股票信息
export const getStock = id => {
    // return jsonp(`//hq.finance.ifeng.com/q.php?l=${id.join(',')}&f=json&from=index`);
    return jsonp(`https://shankapi.ifeng.com/finance/page/newest/h5/${id.join(',')}/getData1`, {
        jsonpCallback: 'getData1',
        cache: false,
    });
};

/**
 * 分页获取碎片列表数据
 * @param {String} fregmentType 碎片类型: 动态碎片 df、自媒体文章列表 wa、 车系文章列表 cs、精品池文章列表 sp
 * @example df|wa|cs|sp
 * @param {String} key 碎片key
 * @example 自媒体key
 * // 文章列表key: 549901-doc
 * // 视频列表key: 549901-video
 * @example 其它碎片id
 * // 200035
 * @param {Number} page 页码
 * @example
 * // 1
 * @param {Number} pageSize 每页数量
 * @example
 * // 15
 */
export const getFeedFlowList = async (fregmentType, key, page, pageSize, seo = false) => {
    console.log(page, pageSize);
    const start = page > 0 ? (page - 1) * pageSize : 0;
    const result = {
        code: 0,
        data: [],
    };

    try {
        const list = await jsonp(
            `${apiBaseUrl}/getFeedflowListBy${
                seo ? '_seo' : ''
            }/${fregmentType}/${key}/${start}/${pageSize}/feedFlowCallback`,
            {
                jsonpCallback: 'feedFlowCallback',
                cache: 120,
            },
        );

        if (list && list.code) {
            result.code = 1;
            result.data = (list.data && list.data.value) || [];
            result.current = page;
            result.totalPage = (list.total && Math.ceil(list.total / pageSize)) || 0;
        }
    } catch (e) {
        result.errMsg = e;

        return result;
    }

    return result;
};
