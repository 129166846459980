import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { formatUrl, formatImage } from '@ifeng-fe/three_terminal/es/base/publicMethod/index';
import { IndexSlides } from '@ifeng-fe/three_terminal/es/mobile/index';
import styles from './index.css';

class SliderComponent extends PureComponent {
    static propTypes = {
        content: PropTypes.array,
        componentOptions: PropTypes.object,
    };

    constructor(...argv) {
        super(...argv);

        this.width = typeof window === 'object' ? window.innerWidth : null;

        const {
            content,
            componentOptions: { extendData, length },
        } = this.props;
        const formatData = this.formatData(content, extendData, length);

        this.state = {
            data: formatData,
        };
    }

    // 轮播图数据处理
    formatData(data = [], extendData = [], length = 5) {
        let dLen = length;
        const width = (typeof window === 'object' ? window.innerWidth : 750) * 2;
        const height = width / 2;

        // 先计算extendData内的数据
        const extendDataResult = [];

        for (const item of extendData) {
            dLen = length - item.componentOptions.length;

            for (const item2 of item.data) {
                const thumbnail = item2?.thumbnail || item2?.thumbnails?.image?.[0]?.url || '';

                extendDataResult.push({
                    id: item2.id,
                    src: formatImage(thumbnail, 698, 350),
                    url: formatUrl(item2.url),
                    title: item2.title,
                });
            }
        }

        // 主数据至少为1条
        if (dLen <= 0) dLen = 1;

        const mainData = [];

        for (let i = 0, j = dLen > data.length ? data.length : dLen; i < j; i++) {
            const item = data[i];
            const thumbnail = item?.thumbnail || item?.thumbnails?.image?.[0]?.url || '';

            mainData.push({
                id: item.id,
                src: formatImage(thumbnail, 698, 350),
                url: formatUrl(item.url),
                title: item.title,
            });
        }

        return mainData.concat(extendDataResult).slice(0, length);
    }

    render() {
        const { data } = this.state;

        if (data && data.length > 0) {
            const { componentOptions } = this.props;
            let ad = undefined;

            // 插入广告
            if (typeof window === 'object' && componentOptions.ad) {
                const dataKey = componentOptions.dataKey || 'allData';

                ad = window[dataKey][componentOptions.ad];
            }

            return (
                <div className={styles.box}>
                    <IndexSlides content={data} ad={ad} />
                </div>
            );
        } else {
            return null;
        }
    }
}

export default SliderComponent;
