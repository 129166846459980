import React, { PureComponent } from 'react';
import styles from './index.css';
import { UpgradeBottomSlides } from '@ifeng-fe/three_terminal/es/mobile/index';

class Share extends PureComponent {
    render() {
        const slidesConfig = {
            apiConfig: {
                type: 'all',
                n: 4,
                from: 'wap_hot_foot_list',
                proid: 'ifeng_wap',
            },
            insertSlideArr: [
                {
                    kind: 'df_sf01',
                    downloads: {
                        download: 'https://a.app.qq.com/o/simple.jsp?pkgname=com.ifeng.news2&ckey=CK1429035767411',
                        downloadAd: 'https://statistics.appstore.ifeng.com/index.php/api/godownload?app_id=4&d_ch=7675',
                    },
                },
                {
                    kind: 'df_sf01',
                    downloads: {
                        download: 'https://a.app.qq.com/o/simple.jsp?pkgname=com.ifeng.news2&ckey=CK1429035767411',
                        downloadAd: 'https://statistics.appstore.ifeng.com/index.php/api/godownload?app_id=4&d_ch=7675',
                    },
                },
                {
                    kind: 'df_sf01',
                    downloads: {
                        download: 'https://a.app.qq.com/o/simple.jsp?pkgname=com.ifeng.news2&ckey=CK1429035767411',
                        downloadAd: 'https://statistics.appstore.ifeng.com/index.php/api/godownload?app_id=4&d_ch=7675',
                    },
                },
                {
                    kind: 'df_sf01',
                    downloads: {
                        download: 'https://a.app.qq.com/o/simple.jsp?pkgname=com.ifeng.news2&ckey=CK1429035767411',
                        downloadAd: 'https://statistics.appstore.ifeng.com/index.php/api/godownload?app_id=4&d_ch=7675',
                    },
                },
            ],
        };

        return (
            <div className={styles.share}>
                <div className={styles.shareFix}>
                    <UpgradeBottomSlides content={slidesConfig} />
                </div>
            </div>
        );
    }
}

export default Share;
