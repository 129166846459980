import React, { PureComponent } from 'react';

const loader = function loader(component) {
    return class extends PureComponent {
        state = {
            Module: null,
        };

        async componentDidMount() {
            const module = await component();

            this.setState({ Module: module.default });
        }

        render() {
            const { Module } = this.state;

            return <div>{Module ? <Module {...this.props} /> : null}</div>;
        }
    };
};

export default loader;
