import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { NewsStream } from '@ifeng-fe/three_terminal/es/mobile/index';

class NewsStreamComponent extends PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    render() {
        return <NewsStream content={this.props.content} isDisplayLoadingMore={false} />;
    }
}

export default NewsStreamComponent;
