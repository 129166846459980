import React, { PureComponent } from 'react';
import Chip from 'Chip';
import PropTypes from 'prop-types';
import ShanklistComponents from '../../ShanklistComponents';

const getType = function getType(type) {
    const types = {
        recommendFragment: 'recommend',
        staticFragment: 'static',
        structuredFragment: 'struct',
    };

    return types[type] ? types[type] : type;
};

class Content extends PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    // 渲染
    componentsView(mainData) {
        const { noAd } = this.props.content;

        return mainData.map((item, index) => {
            const { data, chip, component, componentOptions } = item;

            if (!(component in ShanklistComponents)) return null;

            if (chip.user && chip.user !== '') {
                // 重写编辑碎片的配置
                if (componentOptions.key) chip.id = componentOptions.key;
                if (componentOptions.type) chip.type = getType(componentOptions.type);

                return (
                    <Chip key={index} content={data} {...chip}>
                        {React.createElement(ShanklistComponents[component], { componentOptions, noAd })}
                    </Chip>
                );
            } else {
                return React.createElement(ShanklistComponents[component], {
                    key: index,
                    componentOptions,
                    content: data,
                    noAd,
                });
            }
        });
    }
    render() {
        const { mainData } = this.props.content;

        return this.componentsView(mainData);
    }
}

export { Content };
export default Content;
