import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import NoSSR from 'react-no-ssr';
import ChipEdit from 'ChipEdit';
import Chip from 'Chip';
import transform from 'chipDataTransform';
import { formatUrl } from '@ifeng-fe/three_terminal/es/base/publicMethod';
import Ad from '@ifeng-fe/ui_pc_ad';
import { NormalCaption, SlidesTabs, Copyright, HomeFooter, Hongbao } from '@ifeng-fe/three_terminal/es/mobile/index';
import styles from './index.css';
import Content from './content';
import NewsList from './newsList';
import Share from './share';
// import './vconsole';

const copyrightConfig = {
    text: '京ICP证030609号',
    url: 'https://beian.miit.gov.cn/',
};

const footer = [
    {
        title: '客户端',
        url: '//statistics.appstore.ifeng.com/index.php/api/godownload?app_id=4&d_ch=1399',
    },
    {
        title: '反馈',
        url: '//help.ifeng.com',
    },
    {
        title: '电脑版',
        url: '//ifeng.com',
    },
    // {
    //     title: '无图版',
    //     url: '//t.ifeng.com',
    // },
];

class Layout extends PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    componentDidMount() {
        // 上报首屏时间
        if (window && window.BJ_REPORT) window.BJ_REPORT.firstScreen();
    }

    titleString(channel) {
        const fc = channel[0];

        return [
            {
                title: fc.title,
                url: formatUrl(fc.domain),
            },
        ];
    }

    formatNav(topNav = []) {
        const nav = [];

        for (const item of topNav) {
            const newItem = { ...item };

            if (newItem.mUrl) {
                newItem.url = newItem.mUrl;
            }

            nav.push(newItem);
        }

        return nav;
    }

    render() {
        const { content } = this.props;
        const contentData = {
            mainData: content.mainData,
            noAd: content.noAd,
        };
        const newsStreamData = {
            newsstream: content.newsstream,
            preloadNewsstream: content.preloadNewsstream,
            commonIdArray: content.commonIdArray,
            newsStream: content.newsStream,
            columnId: content.columnId,
            isEnd: content.isEnd,
            isScrollLoadingData: true,
            ad: content.newsStream1Ad,
            isPic: content.isPic,
        };
        const { mobileHidden } = content;

        return (
            <Fragment>
                <NoSSR>
                    <ChipEdit transform={transform} />
                </NoSSR>
                {/* 通栏广告 */}
                {content.passAd ? (
                    <NoSSR>
                        <Ad content={content.passAd} />
                    </NoSSR>
                ) : null}
                {content.nav && !(mobileHidden && mobileHidden.caption) ? (
                    <div className={styles.caption}>
                        <NormalCaption title={this.titleString(content.channel)} content={content.nav} />
                    </div>
                ) : null}
                {content.topNav && content.topNav.length > 0 && !(mobileHidden && mobileHidden.nav) ? (
                    <Chip
                        id={content.topNavId}
                        type="struct"
                        title="导航"
                        groupName="导航栏 "
                        content={this.formatNav(content.topNav)}>
                        <SlidesTabs currentPath={content.columnId} />
                    </Chip>
                ) : null}
                <Content content={contentData} />
                <NewsList content={newsStreamData} />
                <HomeFooter content={footer} />
                <Copyright content={copyrightConfig} />
                {/*
                底部轮播广告
                <NoSSR>
                    <Share />
                </NoSSR>
                */}
                {/* 红包 */}
                <NoSSR>
                    <Chip title="红包" type="struct" id="50189" groupName="红包" content={content.hongbao}>
                        <Hongbao tjKey={content.hongbao ? content.hongbao.listCode : undefined} />
                    </Chip>
                </NoSSR>
                {/* 底部广告 */}
                {content.footerAd ? (
                    <NoSSR>
                        <Ad content={content.footerAd} />
                    </NoSSR>
                ) : null}
            </Fragment>
        );
    }
}

export default Layout;
